<template>
  <div
    v-if="noWrap && show"
    :class="['zoverlay', formattedVariant, { 'zoverlay-fixed': fixed, 'zoverlay-opaque': opaque }]"
    :style="{ zIndex: zIndex }"
    @click="$emit('click', $event)"
  >
    <div class="zoverlay-bg" />
    <div :class="['zoverlay-content', { 'zoverlay-content-center': !noCenter }]">
      <slot name="overlay">
        <ZSpinner />
        <div
          v-if="message"
          class="mt-3"
        >
          {{ message }}
        </div>
      </slot>
    </div>
  </div>

  <div
    v-else
    class="zoverlay-wrapper"
  >
    <!-- wrapped content -->
    <slot />

    <!-- overlay content -->
    <div
      v-if="show"
      :class="['zoverlay', formattedVariant, { 'zoverlay-fixed': fixed, 'zoverlay-opaque': opaque }]"
      :style="{ zIndex: zIndex }"
    >
      <div class="zoverlay-bg" />
      <div :class="['zoverlay-content', { 'zoverlay-content-center': !noCenter }]">
        <slot name="overlay">
          <ZSpinner />
          <div
            v-if="message"
            class="mt-3"
          >
            {{ message }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    noWrap: {
      type: Boolean,
      default: false,
    },

    fixed: {
      type: Boolean,
      default: false,
    },

    noCenter: {
      type: Boolean,
      default: false,
    },

    zIndex: {
      type: String,
      default: '10',
    },

    variant: {
      type: String,
      default: 'light',
    },

    opaque: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },
  },

  emits: ['click'],

  computed: {
    formattedVariant() {
      if (this.isSupportedVariant(this.variant)) {
        return `zoverlay-${this.variant}`
      }
      return 'zoverlay-light'
    },
  },

  methods: {
    isSupportedVariant(variant) {
      return ['primary', 'light', 'transparent', 'clear', 'white'].includes(variant)
    },
  },
}
</script>
